<template>
    <div v-if="widget">
        <loader :loading="loading" :backdrop="true" />

        <!-- Widget components are dynamically registered in main.js -->
        <component
            v-if="widgetComponent"
            :is="widgetComponent"
            :values="pageWidget.values"
            @save-page-widget="editPageWidget"
        ></component>
    </div>
</template>

<script>
    import Auth from "@/mixins/Auth";
    import EventBus from "@/utils/EventBus";
    import Loader from "@/components/ui/Loader";
    import {mapActions, mapGetters} from "vuex";
    import NotifyMixin from "@/mixins/NotifyMixin";
    import ModalNavigation from "@/mixins/ModalNavigation";

    export default {
        mixins: [ ModalNavigation, Auth, NotifyMixin ],
        components: { Loader },
        data: () => {
            return {
                websiteId: null,
                page: null,
                widget: null,
                pageWidget: null,
                widgetValues: [],
                loading: false
            }
        },
        computed: {
            ...mapGetters({
                getPageBySlugAndWebsiteId: 'pages/getPageBySlugAndWebsiteId',
                getWidgetById: 'widgets/getWidgetById',
                getPagesWidgetsByPageNameAndWebsiteId: 'pages_widgets/getPagesWidgetsByPageNameAndWebsiteId',
            }),
            widgetComponent() {
                return () => import('@/components/cms/widgets/' + this.widget.component);
            }
        },
        methods: {
            ...mapActions({
                updatePageWidgetValuesInStore: 'pages_widgets/updatePageWidgetValues',
                deletePageWidgetFromStore: 'pages_widgets/deletePageWidget'
            }),
            editPageWidget(payload) {
                EventBus.emit('disable-primary-footer-button');
                let params = {
                    websiteId: this.websiteId,
                    pageWidgetId: this.pageWidget.id
                };

                this.loading = true;

                this.$cmsDataProvider.create('deletePageWidgetValues', params).then( () => {
                    let order = 1;
                    // save page widget values
                    for (let [key, value] of Object.entries(payload)) {
                        this.widgetValues.push({
                            customers_id: this.customerId,
                            websites_id: this.websiteId,
                            website_pages_name: this.page.website_pages_name,
                            widgets_id: this.widget.id,
                            field_name: key,
                            field_value: value,
                            website_pages_widgets_id: this.pageWidget.id,
                            order_on_page: order
                        });

                        order++;
                    }

                    params = {
                        websiteId: this.websiteId,
                        pageWidgetId: this.pageWidget.id,
                        data: {
                            widgetValues: this.widgetValues
                        }
                    };

                    this.$cmsDataProvider.create('savePageWidgetValues', params).then( () => {
                        this.notifySuccess('The page module was updated successfully');
                        this.$cmsDataProvider.getOne('pageWidget', { websiteId: this.websiteId, pageName: this.page.website_pages_name, id: this.pageWidget.id })
                        .then( (response) => {
                            this.updatePageWidgetValuesInStore({
                                id: this.pageWidget.id,
                                values: response.values
                            });
                            this.loading = false;
                            this.redirect('pages.show', {websiteId: this.websiteId, pageSlug: this.page.url_alias});
                        })
                    });
                });
            },
            deletePageWidget() {
                let params = {
                    websiteId: this.websiteId,
                    pageWidgetId: this.pageWidget.id
                };

                this.$cmsDataProvider.create('deletePageWidgetValues', params)

                params = {
                    websiteId: this.websiteId,
                    pageName: this.page.website_pages_name,
                    id: this.pageWidget.id
                };

                this.$cmsDataProvider.getOne('pageWidget', { websiteId: this.websiteId, pageName: this.page.website_pages_name, id: this.pageWidget.id }).then( () => {
                    this.notifySuccess('The page module was removed successfully.');
                    this.$cmsDataProvider.delete('deletePageWidget', params)
                    .then( (response) => {
                        this.deletePageWidgetFromStore({
                            id: this.pageWidget.id,
                            values: response.values
                        });
                        this.redirect('pages.show', {websiteId: this.websiteId, pageSlug: this.page.url_alias});
                    })
                });
            }
        },
        mounted() {
            this.websiteId = this.$route.params.websiteId;
            this.page = this.getPageBySlugAndWebsiteId(this.$route.params.pageSlug, this.websiteId );
            let pageWidgets = this.getPagesWidgetsByPageNameAndWebsiteId(this.page.website_pages_name, this.websiteId );

            this.pageWidget = pageWidgets.find(item => {
                return item.id === this.$route.params.pageWidgetId;
                }
            );

            this.widget = this.pageWidget.widget;
            this.setActiveModalTitle(this.widget.name);

            EventBus.on('confirm-cms-delete-widget', () => {
                this.deletePageWidget();
            })

            EventBus.on('cancel-confirmation-cms-delete-widget', () => {
                window.console.log('cancel cms-delete-widget');
            })
        },
        unmounted() {
            EventBus.off('confirm-cms-delete-widget');
            EventBus.off('cancel-confirmation-cms-delete-widget');
        },
    }
</script>